<template>
	<div class="layout-menu-container">
		<div align="center">
			<img :src="user.person.image" width="150" height="150" class="img-fluid img-thumbnail" />
		</div>
		<div align="center">
			<small>
				<b>{{ user.person.name }} <br> {{ user.login }}</b>
			</small>
		</div>
		<hr>
		<AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick"
			@keydown="onKeyDown" />
	</div>
</template>

<script>
import AppSubmenu from './AppSubmenu';

import User from "../../models/users";

import UserService from "../../service/user/user_service";

export default {
	props: {
		model: Array
	},
	data() {
		return {
			userService: new UserService(),
			user: new User(),
		};
	},
	methods: {
		onMenuItemClick(event) {
			this.$emit('menuitem-click', event);
		},
		onKeyDown(event) {
			const nodeElement = event.target;
			if (event.code === 'Enter' || event.code === 'Space') {
				nodeElement.click();
				event.preventDefault();
			}
		},
		bannerImage() {
			return this.$appState.darkTheme ? 'images/banner-primeblocks-dark.png' : 'images/banner-primeblocks.png';
		}
	},
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		},
		usuario: {
			get() {
				return this.$store.getters.getUserLogged;
			},
		},
	},
	mounted() {
		this.userService.findById(this.usuario.id).then((data) => {
			this.user = data;
		});
	},
	components: {
		'AppSubmenu': AppSubmenu
	}
}
</script>