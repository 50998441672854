export default class Address {
    constructor() {
        this.id = null;
        this.cep = null;
        this.street = null;
        this.complement = null;
        this.district = null;
        this.city = null;
        this.state = null;
        this.number = null;
    }
}